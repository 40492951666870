@import "../../scss/Variables.scss";



#module-list { margin-top: 48px; height: calc(100% - 138px); display: flex; flex-direction: column; align-items: center; position: relative;
  .avatarBottom{ position: absolute; bottom: 0; right: 24px;}
  #welcome-message { align-self: flex-start; width: 50vw; background: #fff; display: flex; align-items: center; border-radius: 0 56px 56px 0; box-shadow: 4px 4px 8px rgba(0,0,0,0.2); padding: 8px 8px 8px 24px;
    img.avatar-img { max-width: 80px; max-height: 80px; height: auto; }
    div.text-content { margin-left: 16px; }
  }

  #card-container { width: calc(100% - 64px); display: flex; margin: 32px; flex-wrap: wrap;
    > .card { display: flex; position: relative; width: 280px; margin: 0 8px 16px 8px; border-radius: 24px; background: #ececec; flex-direction: column;
      .title {width: calc(100% - 32px); display: flex; flex-direction: column; flex: 1 0 auto; align-items: center;justify-content: center; text-align: center; padding: 16px; color: $inactive-title; font-size: 1.3em; font-weight: bold;
        &.active { color: $colorPrimary; }
      }
      .card-content { width: 100%; padding-bottom: 16px; border-radius: 24px; transition: filter 0.5s; flex-shrink: 0;
        .bar-container { display: flex; flex-direction: column; justify-content: center; align-items: center;
          .bar { display: flex; align-items: center; width: 200px; height: 48px; border-radius: 8px; margin: 8px 0; background: $inactive-bar;
            &.progress-active { background: $progress-background; color: $colorPrimaryLight; }
            .progress-bar { background: $progress-bar; height: 100%; border-radius: inherit; display: flex; align-items: center;
              .text-content { width: 100%; padding: 8px 8px; color: $defaultFontColor;}
            }
            &.success-rate-active { background: $success-bar;  color: $defaultFontColor; border-radius: 8px;
              span { padding: 8px; }
            }                      
            .text-content { width: 100%; padding: 8px 8px; color: inherit; }
          }
        }
      }
      .card-modal { position: absolute; top: 50%; transform: translateY(-50%); width: 100%; display: flex; align-items: center; justify-content: center; transition: filter 0.25s;
        .btn-modal { color: #fff; padding: 24px 40px; background: $colorPrimary; border-radius: 16px; opacity: 0; transition: opacity 0.25s;
        }
      }
      &:hover{cursor: pointer; 
        .card-content, .title{ filter: blur(4px); transition: filter 0.25s;}
        .card-modal {
          .btn-modal { opacity: 1; }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    #card-container{justify-content: center;}
  }
}