@import "../../scss/Variables.scss";

.contentPage {
    position: relative;
    header {
        h1 {
            text-align: center;
            padding: 80px 0 88px 0;
            font-size: 2.8em;
            color: $colorPrimary;
        }
        #content-nav {
            background: $background-color-secondary;
            display: flex;
            justify-content: center;
            li {
                position: relative;
                color: $colorPrimary;
                cursor: pointer;
                padding: 0 16px 10px 16px;
                font-weight: 700;
                font-size: 1em;
                border-bottom: 1px solid $colorPrimary;
                display: flex;
                align-items: center;
                span {
                    &:first-child {
                        padding: 0 4px 0 0;
                    }
                }
                &.current,
                &:hover {
                    position: relative;
                    color: $colorPrimary;
                    &:after {
                        content: "";
                        display: block;
                        height: 4px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                        background-color: $colorPrimary;
                    }
                }
            }
            &.sticky {
                position: fixed;
                left: 0;
                top: 66px;
                width: 100%;
                z-index: 150;
                background-color: $background-color-secondary;
                padding-top: 16px;
            }
        }
    }
    main {
    }
}

@media (max-width: 980px) {
    #contentPage {
        width: 100%;
        > div {
            > header {
                #content-nav {
                    li {
                        flex-direction: column;
                        padding: 0 8px;
                        text-align: center;
                        span {
                            &:last-child {
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    #contentPage {
        > div {
            > header {
                #content-nav {
                    li {
                        flex-direction: column;
                        padding: 0 4px;
                        line-height: 1em;
                        span {
                            &:last-child {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
