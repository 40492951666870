@import "../../scss/Variables.scss";

.demo-banner {
    padding: 8px 0;
    display: flex;
    align-items: center;
    background-color: $colorPrimary;
    position: absolute;
    transition: 0.3s all ease-out;
    > img.logo {
        width: 40px;
    }
    p {
        width: 100%;
        line-height: 1.2em;
        color: white;
    }

    &.top {
        width: 50%;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px;
        button {
            display: none;
        }
        > img.logo {
            margin: 0 0 0 16px;
        }
        p {
            padding: 0 24px 0 8px;
        }
    }

    &.right {
        width: 250px;
        right: 0;
        top: 71px;
        transform: translateX(180px);
        border-radius: 100px 0 0 100px;
        cursor: pointer;
        > img.logo {
            margin: 0 0 0 auto;
        }
        p {
            padding: 0 8px;
        }
    }

    button {
        height: 100%;
        span {
            color: white;
            &.close {
                display: block;
            }
            &.open {
                display: none;
            }
        }
    }

    &.custom{
        position: relative;
        padding: 8px;
        margin: 0 0 8px 0;
        border-radius: 10px;
        background: none;
        border: 2px solid $colorPrimary;
        justify-content: center;
        button{
            display: none;
        }
         img.logo{
             margin: 0 8px 0 0;
         }
         p{
             width: auto;
             color: $defaultFontColor;
             font-weight: 600;
         }
    }

    &.opened {
        &.right {
            transform: translateX(0);
        }
        .close {
            display: none;
        }
        .open {
            display: block;
        }
    }
}
