@import "../../../scss/Variables.scss";
.classrooms-list-container {
    .title {
        color: var(--color-primary);
        font-size: 1.75em;
        border-bottom: 5px solid var(--color-primary);
    }
    .classrooms-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 25px;
        align-items: flex-end;
        .classrooms-tabs {
            border: none;
            margin: 0 16px 16px 0;
        }
        .rc-tabs-tab {
            display: flex;
            justify-content: center;
            width: 80px;
            background: #EEEEEE;
            border-radius: 10px 10px 0px 0px;
            box-shadow: 7px 8px 12px #e8e8e8;
            border: none;
            padding: 20px 30px;
            margin-right: 30px;
            opacity: 0.7;
            font-size: 18px;
            position: relative;
            transform: translateY(8px);
            transition: 0.2s all ease-out;
        }
        .classCode {
            font-size: 1.1em;
            margin: 0 0 16px 0;
        }
        .rc-tabs-tab-btn {
            .tab-title {
                display: flex;
                align-items: center;
            }
            a {
                display: none;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: -4px;
                left: 32px;
                font-weight: 500;
                width: 100px;
                border-radius: 10px;
                span {
                    &:first-child{
                        font-size: 13px;
                        text-decoration: underline;
                    }
                    &:last-child{
                        vertical-align: middle;
                    }
                }
            }
            > div > span {
                display: flex;
                align-items: center;
                color: #FF6138;
            }
        }
        .rc-tabs-tab-active {
            opacity: 1;
            transform: translateY(0);
            &:hover {
                a {
                    display: flex;
                }
            }
        }
        .rc-tabs-ink-bar {
            background: none;
        }
        .rc-tabs-content-holder {
            padding: 25px;
            width: 300px;
            min-height: 360px;
            margin-right: 15px;
            background: #EEEEEE;
            border-radius: 0px 29px 29px 29px;
            box-shadow: 7px 8px 12px #e8e8e8;
            border: none;
            .no-data{
                background-color: white;
                padding: 16px;
            }
        }
    }
    .create-class {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 333px;
        height: 411px;
        margin: 0 0 17px 0;
        background: #eff7f9;
        border: 2px dashed #007fa3;
        box-sizing: border-box;
        border-radius: 30px;
        cursor: pointer;
        > .add-icon {
            font-size: 76px;
            color: $colorPrimary;
        }
        .message {
            font-size: 24px;
            color: #003057;
        }
    }
}
.rc-dialog-wrap {
    margin-top: 10%;
    .rc-dialog-close-x {
        font-size: 30px;
    }
    .add-icon {
        color: $colorPrimary;
    }
    .success-icon {
        color: $colorCorrect;
    }
    .modal-title {
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
            font-weight: 500;
            font-size: 20px;
            color: #007fa3;
            margin-left: 10px;
        }
    }
}
.progress-bar-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.new-class-container {
    .step {
        display: flex;
        flex-direction: column;
    }
    .step-1 {
        padding: 30px;
        .label {
            font-size: 18px;
            color: #b7b7b7;
        }
        .input {
            border-top: none;
            border-left: none;
            border-right: navajowhite;
            border-bottom: 3px solid #007fa3;
            height: 40px;
            font-size: 20px;
        }
    }
    .step-2 {
        position: relative;
        height: 128px;
        align-items: center;
        justify-content: center;
    }
    .step-3 {
        .message {
            text-align: center;
            span {
                display: block;
                &.label {
                    font-size: 1.2em;
                    margin: 0 0 8px 0;
                }
                &.code {
                    font-size: 2.2em;
                    margin: 0 0 24px 0;
                }
            }
        }
        .buttons {
            display: flex;
            justify-content: center;
            > div {
                margin: 0 auto;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 80px;
        align-items: center;
        .add {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;
            width: 147px;
            height: 50px;
            right: 0px;
            top: 0px;
            background: #f6a532;
            border: 1px solid #dedede;
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            flex: none;
            order: 1;
            flex-grow: 0;
            color: white;
            cursor: pointer;
        }
        .cancel {
            color: #8f8f8f;
            font-size: 14px;
            cursor: pointer;
        }
    }
}
