// COLORS
$colorPrimary: var(--color-primary);
$colorPrimaryLight: var(--color-primary-light);
$colorSecondary: var(--color-secondary);
$colorCorrect: var(--color-correct);
$colorError: var(--color-incorrect);
$color-editor-one: var(--color-editor-one);
$color-editor-two: var(--color-editor-two);

$progress-background: #EAF4FD;
$progress-bar: #DBECFC;
$inactive-title: #828282;
$inactive-bar: #E5E5E5;
$success-bar: #FFF1E2;
$success-title: #CE800C;

$background-color-primary: #fff;
$background-color-secondary: #f2f6fb;
// 236 240 241
$background-color-gray: #ecf0f1;

$text-color-primary: #2c3e50;
$neutralGray: #EEEEEE;
$undefined: #979797;

// FONTS
@mixin fontSize($size) {
    font-size: $size;
}
$defaultFontColor: #444;

// OTHER
@mixin borderRadius {
    border-radius: 3px;
}

@mixin widthHeight($size) {
    width: $size + px;
    height: $size + px;
}

@mixin adaptiveWidth($maxWidth) {
    width: 100%;
    max-width: $maxWidth + px;
}

@mixin material-icons {
    direction: ltr;
    display: inline-block;
    font-family: "Material Icons";
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}
