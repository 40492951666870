@import "../../scss/Variables.scss";

.module-list-item {
    background: #fff;
    border: 2px solid $colorPrimary;
    @include borderRadius;
    transition: all 0.2s ease-out;
    .header {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 24px 0;
        background-color: #fff;
        position: relative;
        z-index: 10;
        button {
            margin: 0 16px 0 24px;
            > span {
                &.icon-container-btn {
                    width: 40px;
                    height: 40px;
                    border-width: 2px;
                    border-color: $colorPrimary;
                    span {
                        font-size: 2.8em;
                        color: $colorPrimary;
                        transform-origin: center;
                        transition: all 0.2s ease-out;
                    }
                }
            }
        }
        .title {
            h2 {
                font-size: 1em;
                color: $colorPrimary;
                font-weight: 600;
            }
            > p {
                display: block;
                font-weight: 400;
                padding-right: 16px;
            }
        }
    }
    ul {
        border-top: 1px solid $colorPrimary;
        li {
            display: flex;
            align-items: center;
            font-weight: 500;
            padding: 16px 24px;
            border-bottom: 1px solid #dcdcdc;
            transition: all 0.2s ease-out;
            span {
                &.icon-container-btn {
                    margin-right: 16px;
                    margin-left: 0;
                    color: $colorPrimary;
                    border-width: 2px;
                    @include widthHeight(25);
                    background-color: $colorPrimary;
                    span {
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }
            &:hover {
                padding-left: 32px;
                cursor: pointer;
                background-color: $background-color-secondary;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &.opened {
        .header {
            button {
                > span {
                    &.icon-container-btn {
                        span {
                            transform: rotate(0);
                        }
                    }
                }
            }
        }
        ul {
            display: block;
        }
    }
    &.closed {
        .header {
            button {
                > span {
                    &.icon-container-btn {
                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
        ul {
            display: none;
        }
    }

    /* TEMPORARILY not used, needed for visibility status */
    &.disabled {
        border-color: $undefined;
        .header .title h2,
        .header .title > span,
        .header button > span.icon-container-btn span,
        ul li span.icon-container-btn,
        ul li {
            color: $undefined;
        }
        .header button > span.icon-container-btn,
        ul {
            border-color: $undefined;
        }
        ul li span.icon-container-btn {
            background-color: $undefined;
        }
    }
}
