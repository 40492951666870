.custom-select {
    position: relative;
    width: max-content;

    .custom-select__native-select {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;

        &:focus + .custom-select__visual-select {
            outline: 1px dotted #212121;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: 2px;
        }
    }

    .custom-select__visual-select {
        width: max-content;
        background-color: white;
        padding: 7px 10px;
        padding-right: 31px;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        pointer-events: none;

        .visual-select__drop-icon {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 21px;
            background-color: #ecf0f1;
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
