@import "../../scss/Variables.scss";

.wizard-shell-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .instruction {
        flex: 0 0 auto;
        background-color: white;
        border-bottom: 2px solid $colorPrimaryLight;
        padding: 24px;
        display: flex;
        align-items: center;

        .content {
            @include adaptiveWidth(980);
            margin: 0 auto;

            p {
                font-size: 1.2em;
                text-align: left;
            }
        }
    }

    .gp-container {
        flex: 1 0 auto;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        display: flex;
        flex-direction: row;

        .gp-wrapper {
            flex: 1 1 auto;
            display: inline-block;
            padding: 16px;
            position: relative;
            width: calc(70% - 24px);

            > div {
                padding: 0;
            }
        }

        .wizard-feedback-container {
            flex: 0 0 30%;
            height: 100%;
            background-color: #fff;
            z-index: 1;
            transform: translateX(20%);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .navigation-button {
                padding: 16px;

                .btn {
                    max-width: 200px;
                    margin: 0 auto;
                }
            }

            &.--animate-feedback {
                transform: translateX(0);
                transition: transform 0.2s ease-out;
            }
        }

        .emotional-report__container--wizard {
            position: absolute;
            right: 32px;
            bottom: 32px;

            &.emotional-report__container--feedback {
                right: calc(30% + 32px);
            }
        }
    }
    .exercise-identifier {
        flex: 0 0 auto;
    }
}

.initial-test-modal .rc-dialog {
    position: absolute;
    top: 144px;
    left: 0;
    margin: 0;
    width: 750px;

    .rc-dialog-content {
        border-radius: 0 50px 50px 0;
        background-color: #f2f6fb; // TODO: get color from theme/variables
        box-shadow: 4px 4px 30px #007fa3;

        .rc-dialog-body {
            display: flex;
            align-items: stretch;

            > img {
                margin-left: 88px;
            }

            > p {
                margin-left: 50px;
                margin-right: 64px;
                font-size: 1.125rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                > button {
                    width: auto;
                    margin: 8px auto 0 auto;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .wizard-shell-container .gp-container {
        padding-bottom: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .wizard-shell-container {
        .gp-container {
            flex-direction: column;
            padding-bottom: 80px;

            .gp-wrapper {
                flex: 1 0 auto;
                width: calc(100% - 24px);
            }

            .wizard-feedback-container {
                width: 100%;
                z-index: 300;
                position: fixed;
                left: 0;
                bottom: 67px;
                height: auto;
                flex: 0 0 0;
                transform: translate3d(0, 100%, 0);
                opacity: 1;
                transition: all 0.2s ease-out;

                &.--animate-feedback {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            .emotional-report__container--wizard {
                bottom: 10px;
                right: 0 !important; // Use important to overrive specificity when there is a feedback
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    .initial-test-modal .rc-dialog {
        width: auto;
        right: 10px;

        .rc-dialog-content .rc-dialog-body {
            > img {
                margin-left: 16px;
            }

            > p {
                margin-right: 16px;
            }
        }
    }
}
