@import "../../scss/Variables.scss";

.error-content {
    max-width: 500px;
    width: 100%;
    margin: 32px auto;
    background-color: #fff;
    padding: 32px;
    .error {
    }
}
