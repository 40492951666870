.progress-bar-link {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: white;
    margin: 0 0 16px 0;
    padding: 0 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #a39999;
    position: relative;

    .progressBar {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #bae7e9;
        height: 100%;
        z-index: 10;
        border-radius: 10px;
        .bar{
                height: 50px;
                background-color: #dfeeef;
                border-radius: 10px;
        }
    }
    .progress-bar-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #2f3e4e;
        width: 100%;
        .module-title {
            position: relative;
            width: 100%;
            z-index: 100;
        }
        .module-progression {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            margin: 0 0 0 auto;
            font-weight: lighter;
            z-index: 100;
            border-radius: 10px;
        }
    }
}
