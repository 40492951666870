@import "../../scss/Variables.scss";

#logout{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 65px);
    flex-direction: column;
    img{
        display: block;
        margin: 0 0 8px 0;
    }
    p{
        margin: 0 0 8px 0;
    }
    button{
        max-width: 200px;
    }
}