.bar-link-container {
        border-radius: 10px;
        box-shadow: 0px 0px 5px #726d6d; 
        padding: 16px;
        background-color: #f7f7f7;
        margin-top: 30px;

        .bar-link-text {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 18px;
                color: #2f3e4e;
        }
}