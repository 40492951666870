@import "../../scss/Player.scss";

.playlist-player-container.player-container { height: 100vh;
    .player-header { height: 68px; }

    // STUDENT
    &.player-container--bandit-manchot {

        .player-header {height: auto; padding: 8px 0; align-items: center;

            .player-header__exit {
                // TODO: Sort out color with client variables
                display: flex; padding: 8px 24px; margin:0 0 0 16px; background-color: #C4C4C450; color: white; font-size: 18px; border-radius: 31px; flex-direction: column; justify-content: center; align-items: center;

                .exit__icon { font-size: 32px; }
            }

            .player-header__progression {min-width: 50%;

                h2 { padding: 0 16px; color: white; font-family: 'Roboto', sans-serif; font-size: 18px; font-weight: 700; margin-bottom: 8px;text-align: center; }
                .progress-bar__container{max-width: 500px; width: 100%; margin: 0 auto;}
            }
        }
        .main__wrapper > main {
            // 204 = 155px header + 49px footer
            height: calc(100vh - 204px);
        }
    }
    
    // TEACHER
    .main__wrapper { position: relative; height: 100%; display: flex; flex-direction: column;
        > main {
            flex:1 0 auto;
            // 117 = 68 px header + 49px footer
            height: calc(100vh - 117px);
            display: flex;
            flex-direction: column-reverse; /* 'column' for start, 'column-reverse' for end */
            overflow-y: scroll;
            // You'll break the chatbot greyed background if you add position here
        }
    }

    //WIZARD SHELL SPECIFIC STYLES
    &.wizard-player{
        // STUDENT
        &.player-container--bandit-manchot {
            .main__wrapper{
                > main{overflow-y: auto;}
            }
        }
        //TEACHER
        .main__wrapper{
            main{overflow-y: auto;}
        }
    }

}