@import "../../../scss/Variables.scss";

#home-student {width: 100%; height: 100%; display: flex;

  .block{
    &.left{width: 60%;
      img{display: block; margin: 16px 0 64px 24px;}
    }
    &.right{width: 40%; display: flex;  align-items: flex-start;
      img{display: block; width: 100%; max-width: 500px; height: auto; margin-left: auto;}
    }
  }

  .register-classroom{ padding-left: 24px;
    .header{margin-bottom: 64px;
      h1{font-family: "Roboto-Light"; font-size: 4.5em;color: $colorPrimary; padding-bottom: 32px;}
      .subtitle{display: flex; align-items: center;
        > span{padding: 0 8px 0 0; font-family: "Roboto-Light"; font-size: 2.2em; color: $colorPrimary;}
        .info{ display: inline-block; cursor: pointer; position: relative;
            span.material-icons{display: block; font-size: 1.7em;}
            span.text{display: block; width: 200px; padding: 8px; color: white; font-family: "Roboto"; font-size: 1em; line-height: 1.2em; background-color: #444; border-radius: 5px; position: absolute; left: 32px; top: 50%; opacity: 0; z-index: -1; transform: translateY(-50%);}
            &:hover{
              span.text{opacity: 1; z-index: 10;}
            }
          }
      }
    }
  }

  .signIn{ padding-left: 24px;
    .header{margin-bottom: 64px;
      p{font-size: 32px; font-family: "Roboto-Light"; color: $colorPrimary;}
    }
  }

  .form{width: 50%; min-width: 300px; 
    input{display: block; margin-bottom: 32px; font-family: "Roboto-Light"; padding: 16px; border:none; border-bottom: 2px solid $undefined; background: none; font-size: 32px;
      &:focus{outline: none;}
      &.code{font-family: monospace;}
    }
    .error{display: flex; align-items:center; margin-bottom: 32px;
      span.material-icons{font-size: 32px; color: $colorError; margin-right: 8px;}
      span.text{ font-family: "Roboto-Light"; font-size: 1.2em;}
    }
    .btn{ width: 270px; padding: 16px 0;
      .label{font-family: "Roboto-Light"; font-size: 24px;}
    }
  }

}