@import '../../../scss/Variables.scss';

$blue:#8AC0EC;
$blue-light:#CBE1EA;

.progression-container{
    background-color: rgba(0,0,0,0.5); position: fixed; width: 100%; height: 100%; top:0; left:0; display: flex; align-items: center; justify-content: center; z-index: 200;
    .progression-background{position: absolute; top: 0; left: 0; width: 100%; height: 100%;  z-index: 200;}
    .student-progression{ width: 685px; padding: 32px; position: relative; background-color: $blue-light;  z-index: 300;
        > button{position: absolute; right: -5px; top: 5px;}
        > .container{display: flex; padding: 32px; background-color: $background-color-secondary; border-radius: 5px 5px 0 0;
            .name-container{width: 40%; margin-right: 24px;
                button{border-radius: 5px; padding: 16px;
                    .name{display: flex; align-items: center;
                        span{
                            &.icon{width: 40px; height: 28px; border-radius: 100%;background-color: white; margin-right: 8px; color: $colorPrimary;}
                            &:nth-child(2){ font-size: 1.2em; font-weight: 600; text-align: left;
                                &:after{display: block; width: 100%; height: 2px; margin-top: 2px; background-color: $blue; content:''}
                            }
                        }
                    }
                    .link{ display: block; visibility: hidden; font-size: 12px; font-weight: normal; text-align: left; padding-left: 27%}
        
                    &:hover{ background-color: white; box-shadow: 0 0 8px rgba(0,0,0,0.2);
                        .name{
                            span{
                                &.icon{}
                                &:nth-child(2){
                                    &:after{visibility: hidden;}
                                }
                            }
                        }
                        .link{visibility: visible;}
                    }
                }
            }
            .summary{ width: 100%; padding: 16px; margin-left: auto; background-color: white;border-radius: 5px;
                p{font-size: 0.9em;
                    &:first-child{margin-bottom: 8px;}
                    &:last-child{display: flex; align-items: center;
                        span{
                            &:first-child{ color: $blue;}
                            &:last-child{ padding: 2px 0 0 2px;
                                span{font-weight: 600;}
                            }
                        }
                    }
                }
            }
        }
        .graphic-container{ background-color: white;
            .legend{display: flex; padding: 16px 0;
                ul{display: grid; margin: 0 auto; grid-template-columns: 1fr 1fr; grid-gap: 15px;
                    li{display: flex; align-items: center; font-size: 0.9em;
                        .color{width: 20px; height: 15px; margin-right: 8px; border:1px solid $blue; border-radius: 5px;}
                        &:first-child .color{background-color: $blue-light;}
                        &:nth-child(3) .color{background-color: $blue;}
                        &:nth-child(2) .color{background-color: var(--color-correct);}
                        &:last-child .color{background-color: #FF6138;}
                    }
                }
            }
            > .container{padding-bottom: 16px; position: relative;
                
                .axis{background-color: $blue; position: absolute; bottom: 93px; left: 31px;
                    &.x{height: 1px; width: calc(100% - 110px);
                        span{ width: 60px; top: -15px; right: -65px; }
                    }
                    &.y{height: 65%; width: 1px;
                        span{ width: 100px; top:-20px; left: 50%; margin-left: -50px; text-align: center;}
                    }
                    span{

                        &.arrow-up{ display : block; height : 0; width : 0; border-right : 4px solid transparent; border-bottom : 7px solid $blue; border-left : 4px solid transparent; position: absolute; left: 46px; right: auto; top: -1px;}
                        &.arrow-right{ display : block; height : 0; width : 0; border-top : 4px solid transparent; border-bottom : 4px solid transparent; border-left : 7px solid $blue; position: absolute;right: -1px; top: -3px;}

                        &.text{display: block; position: absolute;color: $blue; font-size: 0.8em;}
                    }
                    .info{ display: inline-block; cursor: pointer; position: absolute;
                        right: -45px;
                        top: 50%; transform: translateY(-50%);
                        span.material-icons{display: block; font-size: 1.7em;}
                        span.text{display: block; width: 200px; padding: 8px; color: white; font-family: "Roboto"; font-size: 1em; line-height: 1.2em; background-color: #444; border-radius: 5px; position: absolute; left:40%; top: -50px; opacity: 0; z-index: -1; transform: translateX(-40%);}
                        &:hover{
                          span.text{opacity: 1; z-index: 10;}
                        }
                      }
                }
        
                .graphic{display: flex; height: 300px; width: 550px; padding-top: 65px;
                    .activity{height: calc(100% - 48px); padding: 0 16px;
                        > .container{ display: flex; padding-bottom: 8px; height: 100%;
                            .exercise{ display: flex; flex-direction: column;
                                .bars{height: 100%; display: flex; align-items: flex-end; margin: 0 24px; position: relative;
                                    > div{ width: 50px;  margin: 0 8px; border-radius: 5px 5px 0 0;}
                                    .expected{background-color: $blue-light; margin-left: 0; border:1px solid $blue; border-bottom: none; box-sizing: border-box;}
                                    
                                    .current{background-color:$blue; margin-right: 0; position: relative; z-index: 200;
                                        > span.material-icons{position: absolute; top: 8px; left: 50%; transform: translateX(-50%); color: white;}
                                        &.positive{background-color: $colorCorrect;}
                                        &.negative{background-color: #FF6138;}

                                        a{display: block; height: 100%;
                                            .material-icons, .icon-improvement{position: absolute; top: -16px; left: 50%; margin-left: -12px; background-color: white; border-radius: 100%;}
                                            .icon-improvement{width: 24px; height: 24px; background-color: $colorCorrect; border:2px solid white; box-sizing: border-box;}
                                            .material-icons{color: #FF6138;}
                                        }
                                    }

                                    .break-progression{cursor: pointer;}

                                    .toolTip{
                                        visibility: hidden; position: absolute; top: -65px; left: -20px; padding: 8px; border-radius: 5px; width: 185px; z-index: 100;
                                        span{
                                            &.text{display: block; font-size: 0.9em; text-align: center; color: white;}
                                            &.liaison{width: 2px; height: 230px; background-color: #FF6138; position: absolute; left: 102px; top: 50px;}
                                        }
                                        &.improvement{background-color: $colorCorrect;
                                            span.liaison{background-color: $colorCorrect;}
                                        }
                                        &.regression{background-color: #FF6138;
                                            span.liaison{background-color: #FF6138;}
                                        }
                                    }
                                    &.showToolTip .toolTip{visibility: visible;}
                                }
                                .title{padding: 2px 0; margin-top: 4px; text-align: center; background-color: $background-color-secondary;
                                    a{text-decoration: underline;}
                                }
                                &:first-child .bars{margin-left: 0;}
                                &:last-child .bars{margin-right: 0;}
                            }
                        }
                        > .title{text-align: center;}
                    }
                }

                /*scrollbar*/
                .simplebar-wrapper{padding: 0px 0px 20px 0; }
                .simplebar-horizontal{ padding: 2px 0; background-color: $blue-light; 
                    &.simplebar-hover{ cursor: pointer;}
                    &:hover{pointer-events: initial;}
                    .simplebar-scrollbar{ top: 2px; border: 1px solid white; border-radius: 10px; box-sizing: border-box; padding: 4px 0;
                        &::before { background-color: $blue; top: 0;}
                    }
                }

            }
        }
        > button{position: absolute; right: 8px; top: 8px;}
    }
}

